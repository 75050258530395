
 
if (document.currentScript) { 

} else {

}
(function($) {

    /*
     *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
     */

    function allClick() { 
        var clickbox = $('.allclick');
        clickbox.each(function() {
            var $this = $(this);
            $this.css('cursor', 'pointer');
            $this.find('a').click(function(event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function() {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function() {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    /*
     *  Funzione per il toggle del menu delle lingue nel mobile
     */
    function mobiToggleLang() {
        if ($("body").is('.mobile')) {
            $(".current-lang").click(function(evt) {
                $(".lang-wrapper").toggleClass("show");
            });
        } else {
            $('.current-lang').click(function(evt) {
                evt.preventDefault();
                $('nav.language').toggle();
            });
        }
    }

    function startOwl(selector) {
        $(selector).each(function(arguments) {
            var owl = $(this);
            var params = {
                navigation : owl.data('owl-navigation'),
                slideSpeed : owl.data('owl-slide-speed'),
                autoPlay : owl.data('owl-autoplay'),
                pagination : owl.data('owl-pagination'),
                paginationSpeed : owl.data('owl-pagination-speed'),
                transitionStyle : owl.data('owl-transition'),
                scrollPerPage : owl.data('owl-scroll-per-page'),
                autoHeight : false,
                navigationText: [
                "<i class='icon-edt-arrow-left-6'></i>",
                "<i class='icon-edt-arrow-right-6'></i>"
                ]
            };

			if(owl.data('owl-nav-container')) {
                params['navContainer'] = owl.data('owl-nav-container');
			}

			if(owl.data('owl-dot-container')) {
                params['dotsContainer'] = owl.data('owl-dot-container');
			}

            if(owl.data('owl-single-item')) {
                params['singleItem'] = true;
            } else {
                params['items'] = owl.data('owl-items-xl'); //numero item da 1680 in su
                params['itemsDesktop'] = [1679,owl.data('owl-items-lg')]; //numero item tra 1679 e 1200
                params['itemsDesktopSmall'] = [1199,owl.data('owl-items-md')]; //numero item tra 1199 e 992 (tablet landscape)
                params['itemsTablet'] = [991,owl.data('owl-items-sm')]; //numero item tra 991 e 768 (tablet portrait)
                params['itemsMobile'] = [767,owl.data('owl-items-xs')]; //numero item da 767 in giu (cell)
            }

            if(owl.hasClass('sync')){
                params['afterAction'] = syncOwl
            }

            owl.owlCarousel(params);

            if(owl.data('owl-prev')){
                $(owl.data('owl-prev')).click(function(){
                    owl.trigger('owl.prev');
                });
            }
            if(owl.data('owl-next')){
                $(owl.data('owl-next')).click(function(){
                    owl.trigger('owl.next');
                });
            }
        });
    }

    function toggleBooking() {
        $(window).scroll(function() {
            var scrolltop = $(window).scrollTop();

            if(scrolltop >= 1750) {
                $('.toggleBooking').addClass('show');
            }
            else {
                $('#booking-adv-lateral').removeClass('show');
                $('#booking-adv-lateral').removeClass('hide');
                $('.toggleBooking').removeClass('show');
            }
        });

        bookingH = $('.content-wrapper .booking').outerHeight();

        /*
        $('.toggleBooking').click(function() {
            $('.toggleBooking').removeClass('show');
            $('#booking-adv-lateral').addClass('show');
            $('#booking-adv-lateral').removeClass('hide');
            $('.content-wrapper .booking').css('height', bookingH);
        });

        $('.closeBooking').click(function() {
            if(scrolltop >= 1750) {
                $('.toggleBooking').addClass('show');
            }
            $('#booking-adv-lateral').addClass('hide');
        });
        */
    }

    function disableList() {
        $('.section-buoni-motivi .item').each(function() {
            if($(this).hasClass('disabled')) {
                var elem = $(this).closest('.allclick');
                
                elem.unbind(allClick())
                    .click(false)
                    .css('cursor', 'default')
                    .removeClass('allclick');
            }
        });
    }


    function scrollAnimations(desktopScrollTop, desktopScrollBol) {
        var header = $('#js-header'),
            menu = $('#js-fixed-menu'),
            windowWidth = $(window).width(),
            headerH = $('#js-header').outerHeight(), // Height compreso di margini e bordi
            menuH = $('#js-fixed-menu').innerHeight(), // Height senza margini e bordi
            scrollValue = headerH - menuH;

        if ($('body').is('.is-mobile')) {

            header.css('height', headerH);

            $(window).scroll(function() {
                scrolltop = $(window).scrollTop();

                if ( scrolltop >= scrollValue) {

                    menu.addClass("scroll");
                
                } else {
                    menu.removeClass("scroll");
                }            
            });

        } else if ( desktopScrollBol ) {
            
            header.before("<div class='header-placeholder'></div>");

            $(window).scroll(function() {
                scrolltop = $(window).scrollTop();

                if ( scrolltop >= scrollValue) {

                    header.addClass("scroll");
                    $(".header-placeholder").css('height', headerH);
                
                } else {
                    header.removeClass("scroll");
                    $(".header-placeholder").attr('style', ''); 
                }            
            });
        }
    }

    function $j(argument) {
        var result = jQuery(argument);
        if (result.length === 0) return null;
        else return result;
    }

    $j(document).ready(function() {
        mobiToggleLang();
        toggleBooking();
        disableList();
        scrollAnimations(300, true);
        startOwl('.owl-carousel');

        $('.booking-box, .input-group-addon.arrow').each(function() {
            $(this).focusin(function(){
                $(this).css('color', '#3d332e');
            });

            $(this).focusout(function(){
                $(this).css('color', '#bfbbb3');
            });
        });

        // $(".gift").click(function(evt){
        //     $(".buonimotivi").toggle();
        // });

        
        
        $('.js-equalHeight-item').equalHeights();
        $('.js-buoni-motivi-box').equalHeights();
        $('.section-camere .item-container-content').equalHeights();
        $('.fixedHeight').equalHeights();
        $('.showall-offerte .box-offer').equalHeights();
        
        
    });

    $j(window).load(function() {});

    $j(window).resize(function() {});

})(jQuery);
